// import { io } from "socket.io-client";
// import { getCookie } from "./Helper";

// import { isReCallSideBar, receivedNewMessage, receivedNewMessageSelectedUser, toogleUserStatus } from "./Store/chatReducer";

// const SOCKET_URL = process.env.REACT_APP_CHAT_APP_URL; // server url

// const socket = io.connect(SOCKET_URL, {
//     autoConnect: false,
//     auth: {
//         token: getCookie()
//     },
//     // pingInterval: 10000, // how often to ping (in milliseconds).
//     // pingTimeout: 10000 // time after which the connection is considered timed-out (in milliseconds).

// }); // socket dashboard

// let previousMessageId = "";

// // Function to initialize the socket connection
// const initializeSocket = (dispatch) => {
//     // Add event listeners for socket connection
//     socket.on("connect", () => {
//         console.log("Connected to server:");
//     });

//     socket.on("newConversation", async (newMessage) => {
//         previousMessageId = newMessage?.document?._id;
//         await dispatch(isReCallSideBar());
//         socket?.off("newConversation");
//     });


//     socket.on("user-status", async (status) => {
//         console.log(status, "status")
//         await dispatch(toogleUserStatus(status));
//         // socket?.off("user-status");
//     });

//     // Add event listeners for socket disconnect
//     socket.on("disconnect", () => {
//         console.log("Disconnected from server");
//     });
// };
//     // socket.off("conversation");

//     // socket.on("conversation", async (lastMessage) => {
//     //     console.log("lastMessage", lastMessage)
//     //     await dispatch(receivedNewMessage(lastMessage?.result?.documents));

//     // })
//     // return lastMessage

// const receiveNewMessageGlobal = (dispatch, USERID, receiverId) => {
//     socket.off("receiveMessage");

//     socket.on("conversation", async (lastMessage) => {
//         console.log("lastMessage", lastMessage)
//         await dispatch(receivedNewMessage(lastMessage?.result?.documents));

//     })
//         // socket?.off("conversation");


//     socket.on("receiveMessage", async (newMessage) => {

//         console.log("HEEELO", newMessage)


//         if (previousMessageId !== newMessage?.document?._id) {
//             previousMessageId = newMessage?.document?._id;
//             await dispatch(receivedNewMessage(newMessage?.document));

//             console.log(USERID, "USERIDUSERID")

//             console.log("under the water under", newMessage)

//             if (USERID === newMessage?.document?.senderId) {

//             await dispatch(receivedNewMessageSelectedUser(newMessage?.document));
//             // await socket.emit("messageReceived", { conversationId: newMessage?.document?._id, userId: uId })
//                 await socket.emit("messageReceived", { receiverId: newMessage?.document?.senderId })
//             }

//         }
//         // socket?.off("receiveMessage");
//     });
// };
// const lastMessage = () => {
//     socket.on("conversation", async (lastMessage) => {
//         console.log("lastMessage", lastMessage)
//     })
// }

// // Function to turn on the socket connection
// const turnOnSocket = () => {
//     socket.connect();
// };

// // Function to turn off the socket connection
// const turnOffSocket = () => {
//     socket.disconnect();
//     console.log("socket", socket)
// };

// const ReceivedMessageOff = () => {
//     socket?.off("receiveMessage");
// };

// export { initializeSocket, turnOnSocket, turnOffSocket, receiveNewMessageGlobal, ReceivedMessageOff };


import { io, Socket } from "socket.io-client";
import { getCookie } from "./Helper";
import { Dispatch } from "redux";  // Assuming you're using Redux for dispatch
import { isReCallSideBar, receivedNewMessage, receivedNewMessageSelectedUser, toogleUserStatus } from "./Store/chatReducer";

// Define the type for new message document if available
interface MessageDocument {
    _id: string;
    senderId?: string;
}
interface UserStatus {
    userId: string;
    statusOnline: boolean;
    // Add any other properties if necessary
}
// Define the type for the status if available
interface UserStatus {
    // Define the properties of UserStatus based on the actual data structure
}

// Define the type for the new message if available
interface NewMessage {
    document?: MessageDocument;
    // Add more properties as required
}

// SOCKET_URL and socket initialization
const SOCKET_URL = process.env.REACT_APP_CHAT_APP_URL as string; // Ensure the environment variable is a string

const socket: Socket = io(SOCKET_URL, {
    autoConnect: false,
    auth: {
        token: getCookie() as string // Assuming getCookie returns a string token
    },
    // pingInterval: 10000,
    // pingTimeout: 10000
});

let previousMessageId = "";

// Function to initialize the socket connection
const initializeSocket = (dispatch: Dispatch) => {
    socket.on("connect", () => {
        console.log("Connected to server:");
    });

    socket.on("newConversation", async (newMessage: NewMessage) => {
        previousMessageId = newMessage?.document?._id ?? "";
        await dispatch(isReCallSideBar());
        socket.off("newConversation");
    });

    socket.on("userStatus", async (status: UserStatus) => {
        const userStatus = {
            userId: status.userId,
            statusOnline: status.statusOnline
        };
    
        await dispatch(toogleUserStatus(userStatus));
        // socket.off("user-status");
    });

    socket.on("disconnect", () => {
        console.log("Disconnected from server");
    });
};

const receiveNewMessageGlobal = (dispatch: Dispatch, USERID: string = "", receiverId: string = "") => {
    
    socket.off("receiveMessage");

    socket.on("conversation", async (lastMessage: { result: { documents: any[] } }) => {
        console.log("lastMessage", lastMessage);
        await dispatch(receivedNewMessage(lastMessage?.result?.documents));
    });

    socket.on("receiveMessage", async (newMessage: NewMessage) => {
        console.log("HEEELO", newMessage);

        if (previousMessageId !== newMessage?.document?._id) {
            previousMessageId = newMessage?.document?._id ?? "";
            await dispatch(receivedNewMessage(newMessage?.document));

            console.log(USERID, "USERIDUSERID");
            console.log("under the water under", newMessage);

            if (USERID === newMessage?.document?.senderId) {
                await dispatch(receivedNewMessageSelectedUser(newMessage?.document));

                await socket.emit("messageReceived", { receiverId: newMessage?.document?.senderId });
                console.log("1")
            }
        }
        // socket.off("receiveMessage");
    });
};

const lastMessage = () => {
    socket.on("conversation", async (lastMessage: any) => {
        console.log("lastMessage", lastMessage);
    });
};

// Function to turn on the socket connection
const turnOnSocket = () => {
    socket.connect();
};

// Function to turn off the socket connection
const turnOffSocket = () => {
    socket.disconnect();
    console.log("socket", socket);
};

const ReceivedMessageOff = () => {
    socket.off("receiveMessage");
};

export { initializeSocket, turnOnSocket, turnOffSocket, receiveNewMessageGlobal, ReceivedMessageOff };
