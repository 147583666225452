import Cookies from 'js-cookie';
import jwtDecode from "jwt-decode";
import CryptoJS from 'crypto-js';
interface JwtPayload {
    [key: string]: any; // Adjust based on your JWT structure
}
type CryptoMessage = string | null;

export const truncateString = (str: string = "", limit: number = 20): string => {
    if (typeof (str) !== "string" || str?.length < limit) {
        return str;
    } else {
        return `${str?.slice(0, limit)}...`
    }
};

export const downloadMedia = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, originalImage: string): Promise<void> => {
    e.preventDefault();
    try {
        fetch(originalImage)
            .then(resp => resp.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;

                const nameSplit = originalImage.split("/");
                const duplicateName = nameSplit.pop();

                // the filename you want
                a.download = "" + duplicateName + "";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.log('Error while downloading the image ', error))

    } catch (error) {
        console.log('Error while downloading the image ', error);
    }
};

export const userSinceDate = (isoDateString: string) => {
    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(isoDateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const month = months[monthIndex];
    const formattedDate = `${day} ${month}, ${year}`;
    return `User since ${formattedDate}`;
};

export const checkUserStatus = (data: Array<{ userId: string; statusOnline?: boolean }>, id: string): string => {
    let finds = data.find(v => v?.userId === id);
    if (finds) {
        return finds?.statusOnline ? "Online" : "Offline";
    } else {
        return "Offline";
    }
};
export const getProfileImage = () => {
    return Cookies.get("_profileImageUrl")
}
export const getCookie = () => {

    return Cookies.get(process.env.REACT_APP_TOKEN_KEY);
};

export const removeCookie = async () => {
    console.log(process.env.REACT_APP_TOKEN_KEY, "process.env.REACT_APP_TOKEN_KEY")
    // await Cookies.remove(process.env.REACT_APP_TOKEN_KEY);
    Cookies.remove('_ndisync_token', { path: '/', domain: process.env.REACT_APP_DOMAIN! });

    // return
    window.location.href = process.env.REACT_APP_LOGIN_PAGE_URL;
};

// export const myAccount  = (): JwtPayload | {} => {
//     let _token = getCookie();
//     if (_token) {
//         let decoded = jwtDecode<JwtPayload>(_token);
//         return decoded;
//     } else {
//         return {};
//     }
// };

export const myAccount = (): JwtPayload => {
    let _token = getCookie();
    if (_token) {
        try {
            let decoded = jwtDecode<JwtPayload>(_token);
            return decoded;
        } catch (error) {
            console.error('Error decoding token:', error);
        }
    }
    return {}; // Return empty object typed as JwtPayload
};

export const formatDate = (date: string) => {
    const hours = new Date(date).getHours();
    const minutes = new Date(date).getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormat = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${twelveHourFormat < 10 ? '0' + twelveHourFormat : twelveHourFormat}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
};
export const formatDateAndDay = (dateString: string) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const twelveHourFormat = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    // Function to determine the ordinal suffix for the day
    const getOrdinalSuffix = (day: number) => {
        if (day > 3 && day < 21) return 'th'; // Special case for 11th to 19th
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const formattedDate = `${month} ${day}${getOrdinalSuffix(day)}`;
    const formattedTime = `${twelveHourFormat < 10 ? '0' + twelveHourFormat : twelveHourFormat}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;

    return `${formattedDate} ${formattedTime}`;
};

// export const decryptMessage = (encryptedMessage) => {
//     const decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, process.env.REACT_APP_SECRET_Key);
//     const decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);
//     return decryptedMessage;
// };

// export const encryptMessage = (message) => {
//     const encryptedMessage = CryptoJS.AES.encrypt(message, process.env.REACT_APP_SECRET_Key).toString();
//     return encryptedMessage;
// };

export const encryptMessage = (message: string): CryptoMessage => {
    try {
        const encryptedMessage = CryptoJS.AES.encrypt(message, process.env.REACT_APP_SECRET_Key).toString();
        return encryptedMessage;
    } catch (error) {
        console.error("Error encrypting message:", error); // Added error handling
        return null; // Return null if encryption fails
    }
};

export const decryptMessage = (encryptedMessage: CryptoMessage): string | null => {
    try {
        if (!encryptedMessage) { // Check if encryptedMessage is valid
            throw new Error("No encrypted message provided.");
        }

        const decryptedBytes = CryptoJS.AES.decrypt(encryptedMessage, process.env.REACT_APP_SECRET_Key);
        const decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);

        if (!decryptedMessage) { // Check if decryption result is valid
            throw new Error("Decryption failed: Malformed UTF-8 data");
        }

        return decryptedMessage;
    } catch (error) {
        console.error("Error during decryption:", error); // Added error handling
        return null; // Return null if decryption fails
    }
};
