/**
 * Component: App
 * Purpose: Main application component handling routing and socket initialization.
 * File Name: App.jsx
 * Author: Developer
 * Date: 06-06-24
 */

import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import "./App.css";
import "../src/assets/css/login.css";

// Layout components
import Sidebar from './NewComponents/Sidebar';
import { initializeSocket, turnOffSocket, turnOnSocket } from './Config/Socket';
import { getCookie } from './Config/Helper';
import Loader from './components/loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Lazy-loaded components
const Home = lazy(() => import('./Screens/Home'));
const Chat = lazy(() => import('./Screens/Chat'));

const App: React.FC = () => {
  const dispatch = useDispatch();
  let token: string | false = getCookie() || false;

  useEffect(() => {
    if (!token) {
      // Redirect to login page if token is not present
      window.location.href = process.env.REACT_APP_LOGIN_PAGE_URL;
    }
    else {
      // Initialize socket connection and turn it on
      initializeSocket(dispatch);
      turnOnSocket();
    }
    return () => {
      // Clean up socket connections when component unmounts (if needed)
      turnOffSocket();
    };
  });

  return (
    <>
      <ToastContainer
        autoClose={2000}
        position="top-right"
      // Render the custom toast component
      />
      <Router>
        <div className="w-full flex" style={{ overflowY: "hidden", height: "100vh" }}>
          <Sidebar />
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:uId/chat" element={<Chat />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </Suspense>
        </div>
      </Router>
    </>
  );
};

export default App;