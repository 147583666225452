import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from 'axios';
import { getCookie } from "../Helper";
import Cookies from 'js-cookie';

interface UserData {
    firstName?: string;
    lastName?: string;
    profileSummary?: string;
    [key: string]: any;
}
interface Conversation {
    _id: string;
    lastMessage: string;
    lastMessageSendBy: string;
    lastMessageTime: string;
    lastMessageType: string;
    UnreadMessages: number;
    UserId: string;
    statusOnline?: boolean;
    isBlocked?: boolean;
    [key: string]: any;

}
interface ChatState {
    reCallSideBar: boolean;
    isLoader: boolean;
    isError: string;
    allconnection: Conversation[];
    blockedUserList: any[];  // Adjust the type according to your data structure
    conversationsData: any[]; // Adjust the type according to your data structure
    selectedUserData: any; // Adjust the type according to your data structure
    isDeleteLoader: boolean;
    sendLoader: boolean;
    blockLoader: boolean;
    blockListLoader: boolean;
    sideBarSearch: string;
    searchList: any[]; // Adjust the type according to your data structure
    directChatSearch: any[]; // Adjust the type according to your data structure
    directChatLoader: boolean;
    filesLoader: boolean;
    filesData: any[]; // Adjust the type according to your data structure
    filesTotalPages: number;
    [key: string]: any;

}
const initialState: ChatState = {
    reCallSideBar: false,
    isLoader: false,
    isError: "error",
    allconnection: [],
    blockedUserList: [],
    conversationsData: [],
    selectedUserData: {},
    isDeleteLoader: false,
    sendLoader: false,
    blockLoader: false,
    blockListLoader: false,
    sideBarSearch: "",
    searchList: [],
    directChatSearch: [],
    directChatLoader: false,
    filesLoader: false,
    filesData: [],
    filesTotalPages: 1,
};

interface DeleteConversationPayload {
    isSuccess: boolean;
    conversationId: string;
}
interface BlockSpamUserPayload {
    isSuccess: boolean;
    uId: string;
}
interface GetConversationResponse {
    uId?: string;
    page?: number;
}
let token = getCookie(); // Retrieve token from cookie
let config = { // API configuration object header
    headers: {
        Authorization: `Bearer ${token}`,
    },
};

// API CALL: Fetch user conversations
export const getMyConversations = createAsyncThunk(
    'chat/getMyConversations',
    async () => {
        try {
            // const conversations = await axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/message/conversations`, config);
            const conversations = await axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/api/chat/all`, config);
            // return conversations?.data;
            return conversations?.data?.documents;
        }
        catch (err: any) {
            if (err?.status === 440) {
                Cookies.remove("_ndisync_token");
                window.location.href = process.env.REACT_APP_LOGIN_PAGE_URL;
            }
            throw err;
        }
    },
); // for side bar

// API CALL: Fetch user's block list
export const getMyBlockList = createAsyncThunk(
    'chat/getMyBlockList',
    async () => {
        try {
            const conversations = await axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/api/block/list?page=1&limit=50`, config);
            return conversations?.data;
        }
        catch (err) {
            throw err;
        }
    },
); // for side bar show block List

// API CALL: Unblock a user
export const userUnBlock = createAsyncThunk(
    'chat/userUnBlock',
    async (id) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_CHAT_APP_URL}/api/block/remove`, { blockedUserId: id }, {


                headers: { // Correctly include headers inside this object
                    Authorization: `Bearer ${token}`,
                },
                // data: { blockedUserId: id }, // `data` is for the request payload
            });

            if (response.status == 200) {
                return id;
            }
            return "";
        }
        catch (err) {
            throw err;
        }
    },
); // for user un-block

// API CALL: Fetch a conversation by user Id
export const getConversation = createAsyncThunk(
    'chat/getConversation',
    async ({ uId, page }: GetConversationResponse) => {
        try {
            // const conversations = axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/message/getConversation/${uId}?page=${page}&limit=100`, config);
            const conversations = axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/api/chat/messages/${uId}?page=${page}&limit=100`, config);
            // const conversations = axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/api/chat/all`, config);
            // const selectedUser = axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/search/view/${uId}`, config);
            const selectedUser = axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/api/search/view/${uId}`, config);
            const [conversationsReponse, selectedUserResponse] = await Promise.all([conversations, selectedUser]);
            return { conversationsReponse, selectedUserResponse, uId };

        }
        catch (err) {
            throw err;
        }
    },
); // for single user conversations / private conversation

// API CALL: Delete a conversation
export const deleteConversation = createAsyncThunk<DeleteConversationPayload, string>(
    'chat/deleteConversation',
    async (conversationId) => {
        try {
            let response = await axios.delete(`${process.env.REACT_APP_CHAT_APP_URL}/api/chat/conversation/${conversationId}`, config);
            let isSuccess = response?.data;
            return { isSuccess, conversationId };
        }
        catch (err) {
            throw err;
        }
    },
); // for delete private conversations

// API CALL: Block a user add block list
export const blockSpamUser = createAsyncThunk<BlockSpamUserPayload, string>(
    'chat/blockSpamUser',
    async (uId) => {
        try {
            let response = await axios.post(`${process.env.REACT_APP_CHAT_APP_URL}/api/block/add`,
                { blockedUserId: uId }, config);
            let isSuccess = response?.data;
            return { isSuccess, uId };
        }
        catch (err) {
            throw err;
        }
    },
); // for delete private conversations

// API CALL: Send a text message
export const TextMessageSend = createAsyncThunk(
    'chat/sendTextMessage',
    async ({ receiverId, messageBody }: { receiverId: string; messageBody: string }) => {
        try {
            let response = await axios.post(`${process.env.REACT_APP_CHAT_APP_URL}/api/chat/message`, {
                receiverId,
                messageBody
            }, config);
            return response?.data?.document;
        }
        catch (err) {
            throw err;
        }
    },
); // for send text message

// API CALL: Send a file
export const fileSend = createAsyncThunk(
    'chat/fileSend',
    async (formData: FormData, thunkAPI) => {
        try {
            let response = await axios.post(`${process.env.REACT_APP_CHAT_APP_URL}/api/chat/send-file`, formData, config);
            return response?.data?.documents;
        }
        catch (err) {
            throw err;
        }
    },
); // for send files

// API CALL: Search connect list
export const searchConnectList = createAsyncThunk(
    'chat/searchConnectList',
    async (val) => {
        try {
            // let response = await axios.get(`${process.env.REACT_APP_IMAGE_URL}/api/connect/connectList?q=${val}&page=1&pageSize=50&sort=desc`, config);
            let response = await axios.get(`${process.env.REACT_APP_CHAT_APP_URL}/api/connection/all-connections?search=${val}&page=1&limit=50&sortBy=desc`, config);
            return response?.data?.documents;
        }
        catch (err) {
            throw err;
        }
    },
); // for send files

// API CALL: Fetch files
export const getFiles = createAsyncThunk(
    'chat/getFiles',
    async ({ uId, site, page, pageSize }: { uId: string; site: string; page: number; pageSize: number }) => {
        try {
            // let url = `${process.env.REACT_APP_CHAT_APP_URL}/message/getFiles/${uId}/${site}?page=${page}&pageSize=${pageSize}`;
            let url = `${process.env.REACT_APP_CHAT_APP_URL}/api/chat/files/${site}/${uId}?page=${page}&limit=${pageSize}`;
            let response = await axios.get(url, config);
            return response?.data;
        }
        catch (err) {
            throw err;
        }
    },
); // for delete private conversations

const chatReducer = createSlice({
    name: "NEWS_FEED",
    // initialState: {
    //     reCallSideBar: false,

    //     isLoader: false,
    //     isError: "error",

    //     allconnection: [],
    //     blockedUserList: [],

    //     conversationsData: [],
    //     selectedUserData: {},

    //     isDeleteLoader: false,
    //     sendLoader: false,

    //     blockLoader: false,
    //     blockListLoader: false,

    //     sideBarSearch: "",
    //     searchList: [],

    //     directChatSearch: [],
    //     directChatLoader: false,

    //     filesLoader: false,
    //     filesData: [],
    //     filesTotalPages: 1,
    // },
    initialState,

    reducers: {
        // receivedNewMessage: (state, { payload }) => {
        //     const conversationIndex = state.allconnection.findIndex(item => item._id === payload._id);
        //     if (conversationIndex !== -1) {
        //         // Update the last message and last message time for that conversation
        //         state.allconnection[conversationIndex].lastMessage = payload?.messageBody;
        //         state.allconnection[conversationIndex].lastMessageSendBy = payload?.senderId;
        //         state.allconnection[conversationIndex].lastMessageTime = payload?.createdAt;
        //         state.allconnection[conversationIndex].lastMessageType = payload?.type;
        //         state.allconnection[conversationIndex].UnreadMessages = (state.allconnection[conversationIndex].UnreadMessages || 0) + 1;

        //         // Move the conversation to the beginning of the array
        //         const conversation = state.allconnection.splice(conversationIndex, 1)[0]; // Remove from current index
        //         state.allconnection.unshift(conversation);
        //     }
        // },
        receivedNewMessage: (state, action: PayloadAction<any>) => {

            const payload = action.payload;
            const updatedConnection = [...state.allconnection];
            // Check if payload is an array
            if (Array.isArray(payload)) {
                payload.forEach((message) => {

                    const conversationIndex = state.allconnection.findIndex(item => item?._id === message._id);
                    if (conversationIndex !== -1) {
                        // Update the last message and last message time for that conversation
                        state.allconnection[conversationIndex].lastMessage = message?.lastMessage;
                        state.allconnection[conversationIndex].lastMessageSendBy = message?.lastMessageSendBy;
                        state.allconnection[conversationIndex].lastMessageTime = message?.lastMessageTime;
                        state.allconnection[conversationIndex].lastMessageType = message?.lastMessageType;
                        // state.allconnection[conversationIndex].UnreadMessages = (state.allconnection[conversationIndex].UnreadMessages || 0) + 1;
                        state.allconnection[conversationIndex].UnreadMessages = message?.UnreadMessages

                        // Move the conversation to the beginning of the array
                        const conversation = state.allconnection.splice(conversationIndex, 1)[0]; // Remove from current index
                        state.allconnection.unshift(conversation);

                    }
                });
            } else {
                // Handle single conversation update if payload is not an array
                const conversationIndex = state.allconnection.findIndex(item => item._id === payload._id);
                if (conversationIndex !== -1) {
                    state.allconnection[conversationIndex].lastMessage = payload?.lastMessage;
                    state.allconnection[conversationIndex].lastMessageSendBy = payload?.lastMessageSendBy;
                    state.allconnection[conversationIndex].lastMessageTime = payload?.lastMessageTime;
                    state.allconnection[conversationIndex].lastMessageType = payload?.lastMessageType;
                    // state.allconnection[conversationIndex].UnreadMessages = (state.allconnection[conversationIndex].UnreadMessages || 0) + 1;
                    state.allconnection[conversationIndex].UnreadMessages = payload?.UnreadMessages

                    // Move the conversation to the beginning of the array
                    const conversation = state.allconnection.splice(conversationIndex, 1)[0]; // Remove from current index
                    state.allconnection.unshift(conversation);
                }
            }
        },
        receivedNewMessageSelectedUser: (state, action: PayloadAction<any>) => {
            const payload = action.payload;


            state.conversationsData = [...state.conversationsData, payload];
            let sideBar = state.allconnection.map(newObj => {
                if (payload?.senderId == newObj?.userId) {

                    console.log(payload?.senderId == newObj?.userId,"payload?.senderId == newObj?.userIdpayload?.senderId == newObj?.userId")
                    return {
                        ...newObj,
                        UnreadMessages: 0,
                    }
                }
                return newObj;
            });
            state.allconnection = sideBar;
            console.log(sideBar,"sideBarsideBar")
        },
        isReCallSideBar: (state) => {
            state.reCallSideBar = !state.reCallSideBar;
        },
        filterFromSideBar: (state, action: PayloadAction<string>) => {
            const payload = action.payload;

            state.sideBarSearch = payload;

            const searchResults = state.allconnection.filter(user => {
                const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
                return fullName.includes(payload.toLowerCase());
            });
            state.searchList = searchResults;
        },
        toogleUserStatus: (state, action: PayloadAction<{ userId: string, statusOnline: boolean }>) => {

            const payload = action.payload;
            let { userId, statusOnline } = payload;
            let sideBar = state.allconnection.map(newObj => {
                if (newObj?.userId === userId) {
                    return {
                        ...newObj,
                        statusOnline: statusOnline
                    }
                }
                return newObj;
            });
            state.allconnection = sideBar;
        }
    },
    extraReducers: (builder) => {
        builder
            // .addCase(getMyConversations.fulfilled, (state, { payload }) => {
            //     state.allconnection = payload?.allConversations || [];
            // })
            .addCase(getMyConversations.fulfilled, (state, { payload }) => {
                state.allconnection = payload || [];
            })
            .addCase(getMyConversations.rejected, (state, { error }) => {
                state.isError = error?.message || "ERROR";
            })
            .addCase(getMyBlockList.pending, (state) => {
                state.blockListLoader = true;
            })
            .addCase(getMyBlockList.fulfilled, (state, { payload }) => {
                state.blockedUserList = payload?.documents || [];
                state.blockListLoader = false;
            })
            .addCase(getMyBlockList.rejected, (state, { error }) => {
                state.blockListLoader = false;
                state.isError = error?.message || "ERROR";
            })
            .addCase(userUnBlock.fulfilled, (state, { payload }) => {
                state.blockedUserList = state.blockedUserList.filter(v => v._id !== payload);
            })
            .addCase(getConversation.pending, (state) => {
                state.isLoader = true;
            })
            .addCase(getConversation.fulfilled, (state, { payload }) => {
                state.isLoader = false;
                console.log(payload,"payload")
                let { conversationsReponse, selectedUserResponse, uId } = payload;
                state.conversationsData = (conversationsReponse?.data?.documents || []).reverse();
                state.selectedUserData = selectedUserResponse?.data?.document || {};
                let sideBar = state.allconnection.map(newObj => {
                    if (newObj.userId == uId) {
console.log(newObj.userId == uId,"newObj.userId == uIdnewObj.userId == uIdnewObj.userId == uId")
                        return {
                            ...newObj,
                            UnreadMessages: 0
                        }
                    }
                    return newObj;
                });
                state.allconnection = sideBar;
            })
            .addCase(getConversation.rejected, (state, { error }) => {
                state.isLoader = false;
                state.isError = error?.message || "ERROR";
            })
            .addCase(deleteConversation.pending, (state) => {
                state.isDeleteLoader = true;
            })
            .addCase(deleteConversation.fulfilled, (state, { payload }) => {
                let { isSuccess, conversationId } = payload

                state.isDeleteLoader = false;
                if (isSuccess) {
                    let sideBar = state.allconnection.map(newObj => {
                        if (conversationId == newObj?._id) {
                            return {
                                ...newObj,
                                lastMessage: "",
                            }
                        }
                        return newObj;
                    });
                    state.allconnection = sideBar;
                }
            })
            .addCase(deleteConversation.rejected, (state, { error }) => {
                state.isDeleteLoader = false;
                state.isError = error?.message || "ERROR";
            })
            .addCase(TextMessageSend.pending, (state) => {
                state.sendLoader = true;
            })
            .addCase(TextMessageSend.fulfilled, (state, { payload }) => {
                state.sendLoader = false;
                state.conversationsData = [...state.conversationsData, payload];
                const conversationIndex = state.allconnection.findIndex(item => item._id === payload.userId);

                if (conversationIndex !== -1) {
                    // Update the last message and last message time for that conversation
                    state.allconnection[conversationIndex].lastMessage = payload?.messageBody;
                    state.allconnection[conversationIndex].lastMessageSendBy = payload?.senderId;
                    state.allconnection[conversationIndex].lastMessageTime = payload?.createdAt;
                    state.allconnection[conversationIndex].lastMessageType = payload?.type;

                    // Move the conversation to the beginning of the array
                    const conversation = state.allconnection.splice(conversationIndex, 1)[0]; // Remove from current index
                    state.allconnection.unshift(conversation);
                }
                else {
                    state.reCallSideBar = !state.reCallSideBar;
                }
            })
            .addCase(TextMessageSend.rejected, (state, { error }) => {
                state.sendLoader = false;
                state.isError = error?.message || "ERROR";
            })
            .addCase(fileSend.pending, (state) => {
                state.sendLoader = true;
            })
            .addCase(fileSend.fulfilled, (state, { payload }) => {
                state.sendLoader = false;
                state.conversationsData = [...state.conversationsData, ...payload];
                let lastIndex = payload?.length - 1;
                const conversationIndex = state.allconnection.findIndex(item => item._id === payload[lastIndex]?.userId);
                if (conversationIndex !== -1) {
                    // Update the last message and last message time for that conversation
                    state.allconnection[conversationIndex].lastMessage = payload[lastIndex]?.messageBody;
                    state.allconnection[conversationIndex].lastMessageSendBy = payload[lastIndex]?.senderId;
                    state.allconnection[conversationIndex].lastMessageTime = payload[lastIndex]?.createdAt;
                    state.allconnection[conversationIndex].lastMessageType = payload[lastIndex]?.type;

                    // Move the conversation to the beginning of the array
                    const conversation = state.allconnection.splice(conversationIndex, 1)[0]; // Remove from current index
                    state.allconnection.unshift(conversation);
                } else {
                    state.reCallSideBar = !state.reCallSideBar;
                }
            })
            .addCase(fileSend.rejected, (state, { error }) => {
                state.sendLoader = false;
                state.isError = error?.message || "ERROR";
            })
            .addCase(blockSpamUser.pending, (state) => {
                state.blockLoader = true;
            })
            .addCase(blockSpamUser.fulfilled, (state, { payload }) => {
                let { isSuccess, uId } = payload
                state.blockLoader = false;
                if (isSuccess) {
                    let sideBar = state.allconnection.map((newObj) => {
                        if (newObj.UserId == uId) {
                            return {
                                ...newObj,
                                isBlocked: true,
                            }
                        }
                        return newObj;
                    });
                    state.allconnection = sideBar;
                }
            })
            .addCase(blockSpamUser.rejected, (state, { error }) => {
                state.blockLoader = false;
                state.isError = error?.message || "ERROR";
            })
            .addCase(searchConnectList.pending, (state) => {
                state.directChatLoader = true;
            })
            .addCase(searchConnectList.fulfilled, (state, { payload }) => {
                state.directChatLoader = false;
                state.directChatSearch = payload;
            })
            .addCase(searchConnectList.rejected, (state, { error }) => {
                state.directChatLoader = false;
                state.isError = error?.message || "ERROR";
            })
            .addCase(getFiles.pending, (state) => {
                state.filesLoader = true;
            })
            .addCase(getFiles.fulfilled, (state, { payload }) => {
                state.filesLoader = false;
                state.filesData = payload?.documents || [];
                state.filesTotalPages = payload?.paginated?.totalPages || 1;
            })
            .addCase(getFiles.rejected, (state, { error }) => {
                state.filesLoader = false;
                state.isError = error?.message || "ERROR";
            })
    },
});

export const { receivedNewMessage, receivedNewMessageSelectedUser, isReCallSideBar, filterFromSideBar, toogleUserStatus } = chatReducer.actions;
export default chatReducer.reducer;