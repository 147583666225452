/**
 * Configuration of the Redux store.
 * Combines all reducers used in the application.
 * 
 * File Name: store.js
 * Author: Developer
 * Date: 06-06-24
 */

import { configureStore } from "@reduxjs/toolkit";
import Chat from "./chatReducer";

export const store = configureStore({
    reducer: {
        chat: Chat, // Reducer for managing chat state
    }
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
