import { CircularProgress } from "@mui/material"

const Loader = () => {
    return (
        <div className="full__page__loader">
            <CircularProgress style={{ color: "#fff" }} />
        </div>
    );
};

export default Loader;